<template>
  <svg
    data-v-431cdece=""
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    :style="fillStyle"
    :width="width"
    :height="height"
  >
    <polygon
      points="40 16 40 53.828 109.024 136 150.815 136 76.896 48 459.51 48 304 242.388 304 401.373 241.373 464 240 464 240 368 208 368 208 496 254.627 496 336 414.627 336 253.612 496 53.612 496 16 40 16"
      class="ci-primary"
    ></polygon>
    <polygon
      points="166.403 248.225 226.864 187.763 204.237 165.135 143.775 225.597 83.313 165.135 60.687 187.763 121.148 248.225 60.687 308.687 83.313 331.314 143.775 270.852 204.237 331.314 226.864 308.687 166.403 248.225"
      class="ci-primary"
    ></polygon>
  </svg>
</template>
<script>
export default {
  name: "IconFunnel",
  computed: {
    fillStyle() {
      return `fill:${this.fillColor}`;
    },
  },
  props: {
    width: {
      type: Number,
      default: 16,
      required: false,
    },
    height: {
      type: Number,
      default: 16,
      required: false,
    },
    fillColor: {
      type: String,
      default: "#000",
      required: false,
    },
  },
};
</script>
