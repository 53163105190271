<template>
  <CCard>
    <CCardHeader class="flex flex--space-between flex--align-items-baseline">
      {{ messages.incidentNfit.title }}
      <ExportExcelButton
        :tableId="tableId"
        @export-table="exportTableToExcel($event)"
      />
    </CCardHeader>
    <CCardHeader class="border-bottom-0 pt-4">
      <CRow>
        <CCol class="form-inline">
          <label class="mfe-2">{{ messages.tables.filter }}</label>
          <input type="text" v-model="searchStr" class="form-control" :placeholder="`${messages.tables.search}...`"/>
          <button class="data-table--clear-button" @click="cleanSearch">
            <IconFunnel v-if="searchStr === ''" fill-color="rgba(60, 75, 100, 0.4)" />
            <IconFunnel v-if="searchStr !== ''" fill-color="#f9524e" />
          </button>
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody class="pt-0">
      <CDataTable
        :ref="tableId"
        :items="filteredTableData"
        :fields="fields"
        hover
        outlined
        border
        clickableRows
        :columnFilter="false"
        :noItemsView="{
          noItems: messages.tables.noItems,
          noResults: `${messages.tables.noResults}`,
        }"
        :items-per-page="25"
        pagination
      >
        <template #status="{ item }">
          <td>
            <CBadge :color="getBadge(item.status)">{{ item.status }}</CBadge>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import TableDataToExcel from "@/helpers/TableDataToExcel";
import IconFunnel from "@/components/atoms/IconFunnel.vue";
const ExportExcelButton = () =>
  import(
    /* webpackChunkName: "exportExcelButton" */ "@/components/molecules/ExportExcelButton"
  );
export default {
  name: "TableIncidentNfit",
  components: {
    ExportExcelButton,
    IconFunnel,
  },
  data() {
    return {
      lang: null,
      messages: null,
      tableId: "TableIncidentNfit",
      searchStr: "",
    };
  },
  computed: {
    items() {
      return this.tableData;
    },
    filteredTableData() {
      return this.filterRows();
    },
    fields() {
      return [
        { key: "ref", label: this.messages.incidentNfit.ref.toUpperCase() },
        {
          key: "nfitId",
          label: this.messages.incidentNfit.nfitId.toUpperCase(),
        },
        {
          key: "nfitDate",
          label: this.messages.incidentNfit.nfitDate.toUpperCase(),
        },
        {
          key: "status",
          label: this.messages.incidentNfit.status.toUpperCase(),
        },
        {
          key: "statusDate",
          label: this.messages.incidentNfit.statusDate.toUpperCase(),
        },
      ];
    },
  },
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getBadge(status) {
      return status === "OK"
        ? "success"
        : status.includes("En curso")
        ? "in-progress"
        : status.includes("Alta")
        ? "upcoming"
        : "";
    },
    exportTableToExcel(tableId) {
      TableDataToExcel(
        this.$refs[tableId]._props.fields,
        this.$refs[tableId]._props.items,
        this.messages.incidentNfit.title
      );
    },
    filterRows() {
      const result = [];
      const searchTerm = this.searchStr.toLowerCase();
      this.tableData.map((row) => {
        let rowPushed = false;
        for (let prop in row) {
          const cellStr =
            prop !== "_class"
              ? typeof row[prop] === "object" &&
                row[prop].hasOwnProperty("value")
                ? row[prop].value.toLowerCase()
                : typeof row[prop] === "string"
                ? row[prop].toLowerCase()
                : ""
              : "";
          if (cellStr.includes(searchTerm) && !rowPushed) {
            result.push(row);
            rowPushed = true;
          }
        }
      });

      if (this.searchStr.length > 0) {
        return result;
      } else {
        return this.tableData;
      }
    },
    cleanSearch() {
      this.searchStr = "";
    },
  },
  created() {
    this.lang = this.$store.getters.getCurrentLang;
    this.messages = this.$store.getters.getLangMessages;
  },
};
</script>

<style lang="scss">
table {
  contain: strict;
}
.badge {
  font-size: 0.875rem;
  font-weight: 400;
  &-success {
    color: #fff;
  }
  &-in-progress {
    background-color: #2b89e7;
  }
  &-upcoming {
    background-color: #ed5a55;
  }
}
.data-table {
  &--filter {
    background: #fff;
  }

  &--filter-input {
    margin: 1rem 0.2rem 1rem 1rem;
    display: inline-block;
    width: auto;
  }

  &--clear-button {
    background-color: transparent;
    border: 0;
    &:focus {outline:0;}
  }

  &--no-items {
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    color: #dedede;
  }
}
</style>
